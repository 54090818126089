<template>
  <div>
    <v-btn color="accent" @click="onAdd()" class="mb-2">Add</v-btn>

    <template>
      <div class="title ma-3">Emails</div>

      <v-data-table
        :headers="headers"
        :items="notificationSubscription"
        class="elevation-1"
        :loading="loading"
      >
        <template v-slot:item.edit="{ item }">
          <v-btn icon @click="onEditNotifier(item)"
            ><v-icon>edit</v-icon></v-btn
          >
        </template>
        <template v-slot:item.remove="{ item }">
          <v-icon @click="onRemoveNotifier(item.email)">delete</v-icon>
        </template>
      </v-data-table>

      <v-divider class="mt-5 mb-5"></v-divider>

      <v-card tile class="pb-5">
        <v-card-title>Push Notification Settings</v-card-title>
        <v-card-text>
          <v-stepper v-model="stepsPushy" vertical>
            <div
              v-for="(
                availableSubscription, index, num
              ) in availablePushySubscriptions"
              :key="index"
            >
              <v-stepper-step
                editable
                :step="++num"
                :complete="stepsPushy > num"
              >
                {{ index.charAt(0).toUpperCase() + index.slice(1) }}
              </v-stepper-step>
              <v-stepper-content :step="num" class="ma-0 pa-0">
                <v-card tile>
                  <v-card-text>
                    <v-stepper value="1" non-linear>
                      <v-stepper-header>
                        <v-stepper-step
                          v-for="(
                            available, index, num
                          ) in availableSubscription"
                          :key="index"
                          editable
                          complete
                          :step="++num"
                        >
                          {{ index }}
                        </v-stepper-step>
                      </v-stepper-header>
                      <v-stepper-content
                        v-for="(available, index, num) in availableSubscription"
                        :key="index"
                        :step="++num"
                        class="ma-0"
                      >
                        <v-data-table
                          :headers="header"
                          hide-default-header
                          :items="available"
                          class="elevation-1"
                        >
                          <template v-slot:item.subscribed="{ item }">
                            <v-checkbox
                              :input-value="userHasSubscribed(item, true)"
                              @change="
                                updateUserSubscription($event, item, true)
                              "
                            ></v-checkbox>
                          </template>
                        </v-data-table>
                      </v-stepper-content>
                    </v-stepper>
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </div>
          </v-stepper>
        </v-card-text>

        <v-card-actions justify-right class="pb-5">
          <v-btn color="accent" class="ml-2" @click="savePushy()"
            >Save Push Settings</v-btn
          >
        </v-card-actions>
      </v-card>

      <!-- DIALOGS -->
      <v-dialog
        v-model="dialog"
        title="Add New Notifier"
        max-width="800px"
        persistent
      >
        <v-card tile id="notifierCard">
          <v-card-title>Notifier Information</v-card-title>
          <v-card-text>
            <v-form v-if="isNew" ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="notifierObject.name"
                    name="firstName"
                    label="Name"
                    :rules="[(v) => !!v || 'Name is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="notifierObject.surname"
                    name="surname"
                    label="Surname"
                    :rules="[(v) => !!v || 'Surname is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="notifierObject.email"
                    name="email"
                    label="Email"
                    :rules="[
                      (v) => !!v || 'Email is required',
                      (v) => verifyEmail(v) || 'Email format incorrect',
                    ]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-form v-else ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="notifierObject.person"
                    name="fullName"
                    label="Fullname"
                    :rules="[(v) => !!v || 'Name & Surname is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="notifierObject.email"
                    name="email"
                    label="Email"
                    :rules="[
                      (v) => !!v || 'Email is required',
                      (v) => verifyEmail(v) || 'Email format incorrect',
                    ]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-card tile>
          <v-card-title>Notification Settings</v-card-title>

          <v-card-text>
            <v-stepper v-model="steps" vertical>
              <div
                v-for="(
                  availableSubscription, index, num
                ) in availableNotificationSubscriptions"
                :key="index"
              >
                <v-stepper-step editable :step="++num" :complete="steps > num">
                  {{
                    index.charAt(0).toUpperCase() + index.slice(1)
                  }}</v-stepper-step
                >
                <v-stepper-content :step="num" class="ma-0 pa-0">
                  <v-card tile>
                    <v-card-text>
                      <v-stepper value="1" non-linear>
                        <v-stepper-header>
                          <v-stepper-step
                            v-for="(
                              available, index, num
                            ) in availableSubscription"
                            :key="index"
                            editable
                            complete
                            :step="++num"
                          >
                            {{ index }}
                          </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-content
                          v-for="(
                            available, index, num
                          ) in availableSubscription"
                          :key="index"
                          :step="++num"
                          class="ma-0"
                        >
                          <v-data-table
                            :headers="header"
                            hide-default-header
                            :items="available"
                            class="elevation-1"
                          >
                            <template v-slot:item.subscribed="{ item }">
                              <v-checkbox
                                :input-value="userHasSubscribed(item)"
                                @change="updateUserSubscription($event, item)"
                              ></v-checkbox>
                            </template>
                          </v-data-table>
                        </v-stepper-content>
                      </v-stepper>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
              </div>
            </v-stepper>
          </v-card-text>

          <v-card-actions justify-right class="pb-5">
            <v-btn color="accent" class="ml-2" @click="saveNotifier()"
              >Save Settings</v-btn
            >
            <v-btn text class="ml-2" @click="cancelNotifier()">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirm" max-width="500px">
        <v-card>
          <v-card-title>Are you sure?</v-card-title>
          <v-card-text>
            You are about to remove {{ confirmRemoveNotifier }}.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="onCancelRemoveNotifier()">Cancel</v-btn>
            <v-btn color="accent" @click="onConfirmRemoveNotifier()">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    name: "NotificationSettings",

    data: function () {
      return {
        subscriptions: [],
        pushySubscriptions: [],

        dialog: false,
        isNew: false,
        valid: false,
        notifierObject: {},

        confirm: false,
        confirmRemoveNotifier: "",

        steps: 0,
        stepsPushy: 0,

        headers: [
          {
            text: "Person",
            value: "person",
          },
          {
            text: "Email",
            value: "email",
          },
          {
            text: "Edit",
            value: "edit",
          },
          {
            text: "Remove",
            value: "remove",
          },
        ],

        header: [
          {
            text: "eventTypeName",
            value: "eventTypeName",
          },
          {
            text: "statusName",
            value: "statusName",
          },
          {
            text: "Subscribed",
            value: "subscribed",
          },
        ],
      };
    },

    computed: {
      notifications: function () {
        return this.$store.state.shops.notifications;
      },
      loading: function () {
        return this.$store.state.shops.loading;
      },
      notificationSubscription: function () {
        return this.$store.state.shops.notificationSubscription;
      },
      availableNotificationSubscriptions: function () {
        return this.$store.state.shops.availableNotificationSubscriptions;
      },
      defaultNotificationSubscriptions: function () {
        return this.$store.state.shops.defaultNotificationSubscriptions;
      },
      availablePushySubscriptions: function () {
        return this.$store.state.shops.availablePushySubscriptions;
      },
      defaultPushySubscriptions: function () {
        return this.$store.state.shops.defaultPushySubscriptions;
      },
    },

    watch: {
      defaultPushySubscriptions: function () {
        this.pushySubscriptions = JSON.parse(
          JSON.stringify(this.defaultPushySubscriptions)
        );
      },
    },

    methods: {
      onPushyLoaded() {
        this.pushySubscriptions = JSON.parse(
          JSON.stringify(this.defaultPushySubscriptions)
        );
      },
      onAdd() {
        this.subscriptions = JSON.parse(
          JSON.stringify(this.defaultNotificationSubscriptions)
        );
        this.isNew = true;
        this.dialog = true;
      },
      onRemoveNotifier(email) {
        this.confirm = true;
        this.confirmRemoveNotifier = email;
      },
      onConfirmRemoveNotifier() {
        this.$store.dispatch(
          "shops/removeNotifier",
          this.confirmRemoveNotifier
        );
        this.confirm = false;
      },
      onCancelRemoveNotifier() {
        this.confirm = false;
        this.confirmRemoveNotifier = "";
      },
      userHasSubscribed(item, pushy) {
        if (pushy) {
          // Pushy subscriptions
          return this.pushySubscriptions.some(
            (el) =>
              el.eventTypeId === item.eventTypeId &&
              el.partyTypeId === item.partyTypeId &&
              el.statusId === item.statusId
          );
        } else {
          return this.subscriptions.some(
            (el) =>
              el.eventTypeId === item.eventTypeId &&
              el.partyTypeId === item.partyTypeId &&
              el.statusId === item.statusId
          );
        }
      },
      updateUserSubscription(val, item, pushy) {
        if (pushy) {
          if (val) {
            this.pushySubscriptions.push(item);
          } else {
            for (let i = 0; i < this.pushySubscriptions.length; i++) {
              if (
                this.pushySubscriptions[i].eventTypeId === item.eventTypeId &&
                this.pushySubscriptions[i].partyTypeId === item.partyTypeId &&
                this.pushySubscriptions[i].statusId === item.statusId
              ) {
                this.pushySubscriptions.splice(i, 1);
              }
            }
          }
        } else {
          if (val) {
            this.subscriptions.push(item);
          } else {
            for (let i = 0; i < this.subscriptions.length; i++) {
              if (
                this.subscriptions[i].eventTypeId === item.eventTypeId &&
                this.subscriptions[i].partyTypeId === item.partyTypeId &&
                this.subscriptions[i].statusId === item.statusId
              ) {
                this.subscriptions.splice(i, 1);
              }
            }
          }
        }
      },
      onEditNotifier(item) {
        this.notifierObject = { ...item };
        this.subscriptions = item.config;

        this.isNew = false;
        this.dialog = true;
      },
      async saveNotifier() {
        const fullName = this.notifierObject.person
          ? this.notifierObject.person
          : `${this.notifierObject.name} ${this.notifierObject.surname}`;

        const notifyApiObj = {
          person: fullName,
          email: this.notifierObject.email,
        };

        if (this.$refs.form.validate()) {
          let notifier = { ...notifyApiObj, config: { ...this.subscriptions } };

          let success = await this.$store.dispatch(
            "shops/updateNotifierSettings",
            notifier
          );

          this.$refs.form.reset();
          this.dialog = false;
          this.steps = 0;
        }
      },
      cancelNotifier() {
        this.$refs.form.reset();
        this.steps = 0;
        this.dialog = false;
      },
      async savePushy() {
        let success = await this.$store.dispatch(
          "shops/updatePushySettings",
          this.pushySubscriptions
        );
        this.stepsPushy = 0;
      },
      verifyEmail(email) {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        );
      },
    },

    created: function () {
      this.$store.dispatch("shops/fetchAvailableNotificationSubscriptions");
      this.$store.dispatch("shops/fetchDefaultNotificationSubscriptions");
      this.$store.dispatch("shops/fetchAvailablePushySubscriptions");
      this.$store.dispatch("shops/fetchDefaultPushySubscriptions");
    },

    mounted: function () {
      this.$store.dispatch("shops/fetchNotifications");
      this.$store.dispatch("shops/fetchNotificationsSubscriptions");
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .v-stepper__step:hover,
  .v-stepper__step--active {
    background: #faa225 !important;
  }

  .v-stepper--vertical {
    padding-bottom: 0;
  }

  .v-stepper--vertical .v-stepper__step {
    padding: 16px 24px;
  }
</style>
