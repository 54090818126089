<template>
  <!-- Special TODO note: We will need to look at this again. There is few improvements do in the generating of the dom -->
  <div>
    <v-expansion-panels multiple v-model="expansionValues">
      <template
        v-for="(item, feeTypeIndex) in this.fees.marketplaceTransactions"
      >
        <v-expansion-panel
          v-for="(item, subTypeIndex) in item"
          :key="feeTypeIndex + '-' + subTypeIndex"
          :ref="feeTypeIndex + '-' + subTypeIndex"
        >
          <v-expansion-panel-header>
            <div>
              {{ splitCamelCaseToString(feeTypeIndex) }} -
              <span class="font-weight-bold">{{
                splitCamelCaseToString(subTypeIndex)
              }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card color="grey lighten-3">
              <v-layout
                v-for="(item, subTypeIndex1, index) in item"
                :key="subTypeIndex1"
                align-center
                :class="index & 1 ? '' : 'grey lighten-2'"
                pl-2
                py-1
              >
                <template v-if="item.fixed !== undefined">
                  <v-flex xs3>
                    <div>{{ splitCamelCaseToString(subTypeIndex1) }}</div>
                  </v-flex>
                  <v-flex xs3 pr-3>
                    Fixed:
                    <strong
                      >R
                      {{
                        fees.marketplaceTransactions[feeTypeIndex][
                          subTypeIndex
                        ][subTypeIndex1]["fixed"]
                      }}</strong
                    >
                  </v-flex>
                  <v-flex>
                    <strong>+</strong>
                  </v-flex>
                  <v-flex xs3>
                    Percentage:
                    <strong
                      >{{
                        fees.marketplaceTransactions[feeTypeIndex][
                          subTypeIndex
                        ][subTypeIndex1]["percentage"]
                      }}
                      %</strong
                    >
                  </v-flex>
                </template>
                <template v-else-if="subTypeIndex1 === 'fixed'">
                  <v-flex xs6 pr-3>
                    Fixed:
                    <strong
                      >R
                      {{
                        fees.marketplaceTransactions[feeTypeIndex][
                          subTypeIndex
                        ]["fixed"]
                      }}</strong
                    >
                  </v-flex>
                  <v-flex>
                    <strong>+</strong>
                  </v-flex>
                  <v-flex xs3>
                    Percentage:
                    <strong
                      >{{
                        fees.marketplaceTransactions[feeTypeIndex][
                          subTypeIndex
                        ]["percentage"]
                      }}
                      %</strong
                    >
                  </v-flex>
                </template>
              </v-layout>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </div>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    name: "FeesSettings",

    data: function () {
      return {
        expansionValues: [],
        count: 0,
      };
    },

    computed: {
      fees: function () {
        return this.$store.state.shops.fees;
      },
    },

    watch: {
      fees: {
        immediate: true,
        deep: true,
        handler(fees) {
          this.count = 0;
          for (let model in this.fees.marketplaceTransactions) {
            for (let subModel in this.fees.marketplaceTransactions[model]) {
              this.setExpansion(
                this.fees.marketplaceTransactions[model][subModel]
              );
              this.count++;
            }
          }
        },
      },
    },

    methods: {
      setExpansion(items) {
        for (let subItem in items) {
          if (typeof items[subItem] !== "object") {
            if (items.fixed !== 0 || items.percentage !== 0) {
              this.expansionValues.push(this.count);
            }
          } else if (
            items[subItem].fixed !== 0 ||
            items[subItem].percentage !== 0
          ) {
            this.expansionValues.push(this.count);
          }
        }
      },
      splitCamelCaseToString(val) {
        return (
          val
            // replace underscore with space
            .replace(/_/g, " ")
            // insert a space before all caps
            .replace(/([A-Z])/g, " $1")
            // uppercase the first character
            .replace(/^./, function (str) {
              return str.toUpperCase();
            })
        );
      },
    },

    mounted: function () {
      this.$store.dispatch("shops/fetchFees");
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
