<template>
  <S2SForm title="Settings">
    <v-card>
      <template>
        <v-tabs v-model="activeTabItem" dark background-color="secondary" grow>
          <v-tabs-slider color="accent"></v-tabs-slider>
          <v-tab v-for="tab in tabs" :key="tab.id" :href="`#${tab.id}`">{{
            tab.name
          }}</v-tab>
        </v-tabs>
      </template>
      <v-tabs-items v-model="activeTabItem">
        <v-tab-item v-for="tab in tabs" :key="tab.id" :value="tab.id">
          <v-card flat>
            <v-card-text>
              <sales v-if="tab.id == 'sales'"></sales>
              <fees v-else-if="tab.id == 'fees'"></fees>
              <notifications
                v-else-if="tab.id == 'notifications'"
              ></notifications>
              <my-shop-profile
                v-else-if="tab.id == 'profile'"
              ></my-shop-profile>
              <addresses v-else-if="tab.id == 'addresses'"></addresses>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import Sales from "@/views/settings/Sales.vue";
  import Fees from "@/views/settings/Fees.vue";
  import Notifications from "@/views/settings/Notifications.vue";
  import MyShopProfile from "@/views/settings/MyShopProfile.vue";
  import Addresses from "@/views/settings/Addresses.vue";

  /** As soon as there is more settings, this component will change in tab navigation component like in admin dashboard */

  export default Vue.extend({
    name: "Settings",

    components: { Sales, Fees, Notifications, MyShopProfile, Addresses },

    props: {
      activeTab: {
        type: String,
        default: "",
      },
    },

    data: function () {
      return {
        tabs: [
          { id: "sales", name: "Sales" },
          { id: "fees", name: "Fees" },
          { id: "notifications", name: "Notifications" },
          { id: "profile", name: "My Shop Profile" },
          { id: "addresses", name: "Addresses" },
        ],

        activeTabItem: "sales",
      };
    },

    watch: {
      activeTabItem: function (val) {
        this.$router.replace({ name: "settings", params: { activeTab: val } });
      },
    },

    created: function () {
      this.activeTabItem = this.$props.activeTab;
    },
  });
</script>
