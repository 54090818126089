<template>
  <v-dialog v-model="_dialog" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>{{
        title
      }}</v-card-title>

      <v-card-text>
        <slot></slot>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="button-container"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    name: "SimpleDialog",

    model: {
      prop: "dialog",
      event: "change",
    },

    props: {
      dialog: {
        type: Boolean,
      },
      title: {
        type: String,
        default: "SimpleDialog",
      },
    },

    computed: {
      _dialog: {
        get() {
          return this.dialog;
        },
        set(val) {
          this.$emit("change", val);
        },
      },
    },
  });
</script>

<style scoped></style>
