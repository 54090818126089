<template>
  <v-container grid-list-xs>
    <v-layout row wrap>
      <v-flex xs12>
        <div class="text-xs-left">Offering</div>
      </v-flex>
      <v-flex xs12 sm6 md3>
        <v-checkbox
          class="mt-0"
          color="secondary"
          label="Payments"
          v-model="settingsModel.acceptPayments"
        ></v-checkbox>
      </v-flex>
      <v-flex xs12 sm6 md3>
        <v-checkbox
          class="mt-0"
          color="secondary"
          label="Orders"
          v-model="settingsModel.acceptOrders"
        ></v-checkbox>
      </v-flex>
      <v-flex xs12>
        <div class="text-xs-left">Order Payments</div>
      </v-flex>
      <v-flex xs12 sm6 md3>
        <v-checkbox
          class="mt-0"
          color="secondary"
          label="Pay Upfront"
          v-model="settingsModel.requirePayUpfront"
        ></v-checkbox>
      </v-flex>
      <v-flex xs12 sm6 md3>
        <v-checkbox
          class="mt-0"
          color="secondary"
          label="Pay on Delivery"
          v-model="settingsModel.acceptPayOnDelivery"
        ></v-checkbox>
      </v-flex>
      <v-flex xs12>
        <div class="text-xs-left">Fulfillment</div>
      </v-flex>
      <v-flex xs12 sm6 md3>
        <v-checkbox
          class="mt-0"
          color="secondary"
          label="Delivery"
          v-model="settingsModel.acceptDeliveries"
        ></v-checkbox>
      </v-flex>
      <v-flex xs12 sm6 md3>
        <v-checkbox
          class="mt-0"
          color="secondary"
          label="Collection"
          v-model="settingsModel.acceptCollections"
        ></v-checkbox>
      </v-flex>
    </v-layout>
    <v-card v-if="settingsModel.acceptDeliveries">
      <v-card-title>Delivery Settings</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <div class="mb-4">
              <label>Delivery Fee</label><br />
              <div
                v-if="!setFee"
                @click="setFee = true"
                class="delivery-fee primary--text"
              >
                {{ formatCurrency(deliveryFee) }}
                <v-icon class="float-right">edit</v-icon>
              </div>
              <div v-else>
                <v-text-field
                  :rules="[
                    (v) =>
                      /^\d+(.\d{2})?$/.test(v) ||
                      'Number input only (E.g 5.00)',
                  ]"
                  v-model="deliveryFee"
                ></v-text-field>
                <v-btn color="accent" @click="updateFee()">Update fee</v-btn>
              </div>
            </div>

            <div>
              <label>Delivery Provider</label><br />
              <v-radio-group v-model="deliveryProvider">
                <v-radio
                  color="secondary"
                  v-for="provider in providers"
                  :key="provider.id"
                  :label="provider.name"
                  :value="provider.id"
                ></v-radio>
              </v-radio-group>
            </div>

            <div>
              <label>Require Boxes</label><br />
              <v-switch
                color="secondary"
                v-model="requireBoxes"
                :label="`${requireBoxes ? 'Yes' : 'No'} `"
                @change="updateRequireBoxes(requireBoxes)"
              ></v-switch>
            </div>
          </v-col>
          <!-- <v-col cols="12" sm="8">
            <v-text-field
              :rules="[(v) => /^\d+$/.test(v) || 'Number input only (E.g 50)']"
              v-model="deliveryDistance"
              label="Delivery Distance (km)"
              @blur="saveDeliveryRestrictions()"
            ></v-text-field>
            <v-autocomplete
              ref="search"
              v-model="selectedAddress"
              :filter="searchResultFilter"
              label="Delivery Places"
              :items="searchItems"
              return-object
              :search-input.sync="searchText"
              placeholder="eg: Cape Town"
              cy-data="address-search"
              clearable
            >
              <template slot="no-data">
                <v-list
                  v-if="searchText == null || searchText == ''"
                  flat
                  class="pa-2"
                >
                  Start searching...
                </v-list>
                <v-list v-else flat class="pa-2"> No address found. </v-list>
              </template>
            </v-autocomplete>
            <div v-if="deliveryPlaces.length">
              <v-list-item
                v-for="(place, index) of deliveryPlaces"
                :key="place.name"
                class="elevation-2"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ place.province }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    getPlaceName(place)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon text @click="removePlace(index)"
                    ><v-icon>delete</v-icon></v-btn
                  >
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-col> -->
        </v-row>
        <!-- Box Sizes -->
        <v-row>
          <v-col cols="12">
            <v-layout v-show="requireBoxes">
              <label>Allowed Box Sizes</label>
              <v-row>
                <v-col cols="12" md="4" v-for="size in boxSizes" :key="size.id">
                  <v-checkbox
                    color="secondary"
                    :label="size.name"
                    :messages="size.description"
                    :value="size.id"
                    v-model="selected[size.id - 1]"
                    @change="updateBoxSize(size.id)"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import Vue from "vue";
  import { Utils } from "@/utils";

  /* const orgModule = namespace("shops");
  const ordersModule = namespace("orders");
  const addrModule = namespace("addresses"); */

  export default Vue.extend({
    name: "SalesSettings",

    data: function () {
      return {
        selectedAddress: null,
        latLng: {},
        addressModel: {},
        //deliveryPlaces: [],
        //searchText: null,

        settingsModel: null,

        setFee: false,
        deliveryFee: 0,
        //deliveryDistance: 0,
        deliveryProvider: 0,
        requireBoxes: false,

        // selected sizes
        selected: [],
      };
    },

    computed: {
      settings: function () {
        return this.$store.state.shops.settings;
      },
      searchItems() {
        const result = this.geoSearchAddresses.map((entry) => {
          const suburb = entry.Location.Address.County;
          const city = entry.Location.Address.City;
          const streetName = entry.Location.Address.Street;
          const streetNumber = entry.Location.Address.HouseNumber;
          const province = entry.Location.Address.State;
          const postalCode = entry.Location.Address.PostalCode;
          const name = entry.Location.Address.Label;
          const latitude = entry.Location.DisplayPosition.Latitude;
          const longitude = entry.Location.DisplayPosition.Longitude;
          let description = entry.Location.Address.Label;
          description += suburb ? ", " + suburb : "";

          this.latLng = { lat: latitude, lng: longitude };

          return {
            text: description,
            value: {
              name,
              country: "South Africa",
              province,
              city,
              suburb,
              streetName,
              streetNumber,
              postalCode,
              latitude,
              longitude,
            },
          };
        });

        return result;
      },
      providers: function () {
        return this.$store.state.orders.providers;
      },
      boxSizes: function () {
        return this.$store.state.orders.boxSizes;
      },
      allowedBoxSizes: function () {
        return this.$store.state.orders.allowedBoxSizes;
      },
      deliverySettings: function () {
        return this.$store.getters["orders/deliverySettings"];
      },
      deliveryRestrictions: function () {
        return this.$store.getters["orders/deliveryRestrictions"];
      },
      allowedBoxSizesArray: function () {
        return this.$store.getters["orders/allowedBoxSizesArray"];
      },
      geoSearchAddresses: function () {
        return this.$store.state.addresses.geoSearchAddresses;
      },
      selectedAddressState: function () {
        return this.$store.state.addresses.selectedAddress;
      },
    },

    watch: {
      settings: {
        deep: true,
        immediate: true,
        handler(newVal, oldVal) {
          if (!this.settingsModel) this.settingsModel = this.settings;
        },
      },

      settingsModel: {
        deep: true,
        handler(newVal, oldVal) {
          this.$store.dispatch("shops/updateSettings", newVal);
        },
      },

      deliverySettings: {
        deep: true,
        immediate: true,
        handler(newVal, oldVal) {
          if (!this.deliveryFee) this.deliveryFee = this.deliverySettings.fee;
          if (!this.deliveryProvider)
            this.deliveryProvider = this.deliverySettings.provider.id;
          if (!this.requireBoxes)
            this.requireBoxes = this.deliverySettings.requireBoxes;
        },
      },

      allowedBoxSizesArray: {
        deep: true,
        immediate: true,
        handler(newVal, oldVal) {
          if (!this.selected.length) this.selected = newVal;
        },
      },

      deliveryProvider: {
        deep: true,
        handler(newVal, oldVal) {
          if (oldVal > 0) {
            this.$store.dispatch("orders/updateDeliveryProvider", {
              fee: this.deliveryFee,
              warehouseId: "",
              provider: { id: newVal },
            });
          }
        },
      },

      /* deliveryRestrictions: {
          deep: true,
          immediate: true,
          handler(newVal, oldVal) {
            if (
              this.deliveryRestrictions.distance &&
              this.deliveryRestrictions.places
            ) {
              if (!this.deliveryDistance)
                this.deliveryDistance = this.deliveryRestrictions.distance;
              if (!this.deliveryPlaces.length)
                this.deliveryPlaces = this.deliveryRestrictions.places
                  ? this.deliveryRestrictions.places
                  : [];
            }
          },
        }, */

      /* searchText: {
          deep: true,
          async handler() {
            if (!this.searchText) return;

            try {
              await this.$store.dispatch(
                "addresses/searchAddress",
                this.searchText
              );
            } catch (error) {
              /* eslint no-console: ["error", { allow: ["warn", "error"] }]
              console.error(error);
            }
          },
        }, */

      /* selectedAddress: {
          deep: true,
          handler(val) {
            const allowedKeys = [
              "country",
              "province",
              "city",
              "suburb",
              "street",
            ];

            for (let [key, value] of Object.entries(val.value)) {
              if (!allowedKeys.includes(key)) {
                // simply remove key for backend support
                delete val.value[key];
              }
            }

            this.addressModel = { ...this.addressModel, ...val.value };
            this.deliveryPlaces.push({ ...this.addressModel, ...val.value });

            this.saveDeliveryRestrictions();
          },
        },*/
    },

    methods: {
      updateFee() {
        this.setFee = false;
        this.$store.dispatch("orders/updateDeliveryFee", this.deliveryFee);
      },
      updateBoxSize(sizeId) {
        let addSize = this.selected.includes(sizeId) ? true : false;
        if (addSize) {
          // Add
          this.$store.dispatch("orders/addBoxSizes", sizeId);
        } else {
          // Remove
          this.$store.dispatch("orders/removeBoxSizes", sizeId);
        }
      },
      updateRequiredBoxes(requiredBoxes) {
        this.$store.dispatch("orders/updateRequiredBoxes", requiredBoxes);
      },
      searchResultFilter(item, queryText, itemText) {
        return true;
      },
      /* removePlace(index) {
          this.deliveryPlaces.splice(index, 1);
          this.saveDeliveryRestrictions();
        },
        getPlaceName(place) {
          let placeName = [];
          const allowedKeys = ["country", "province", "city", "suburb", "street"];

          for (let [key, value] of Object.entries(place)) {
            if (allowedKeys.includes(key) && value != null) {
              placeName.push(place[key]);
            }
          }

          return placeName.join(", ");
        },
        saveDeliveryRestrictions() {
          this.$store.dispatch("orders/updateDeliveryRestrictions", {
            distance: this.deliveryDistance,
            places: this.deliveryPlaces,
          });
        }, */
      formatCurrency(text) {
        return Utils.formatText(text, Utils.TextType.CURRENCY);
      },
    },

    created: function () {
      this.$store.dispatch("orders/fetchDeliverySettings");
      this.$store.dispatch("orders/fetchDeliveryRestrictions");
      // system providers
      this.$store.dispatch("orders/fetchProviders");
      // system box sizes
      this.$store.dispatch("orders/fetchBoxSizes");
      // allowed boxes for org
      this.$store.dispatch("orders/fetchAllowedBoxSizes");
    },

    mounted: async function () {
      this.deliveryFee = this.deliverySettings.fee;
      this.deliveryProvider = this.deliverySettings.provider.id;
      this.requireBoxes =
        this.deliverySettings.requireBoxes != null
          ? this.deliverySettings.requireBoxes
          : false;
      this.selected = this.allowedBoxSizesArray;

      /* this.deliveryDistance = this.deliveryRestrictions.distance;
        this.deliveryPlaces = this.deliveryRestrictions.places
          ? this.deliveryRestrictions.places
          : []; */
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .delivery-fee {
    cursor: pointer;
    font-size: 15px;
  }
</style>
