var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","fullscreen":"","persistent":"","max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.mapDialog),callback:function ($$v) {_vm.mapDialog=$$v},expression:"mapDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.mapDialog = false}}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',[_vm._v("Address Location")]),_c('v-spacer')],1),(_vm.mapDialog)?_c('leaflet-map',{attrs:{"lat-lng":_vm.addressLatLng,"deliveryRadius":_vm.deliveryRadius,"collectionRadius":_vm.collectionRadius,"isPrimaryAddress":_vm.isPrimaryAddress,"readonly":""},on:{"update:latLng":function($event){_vm.addressLatLng=$event},"update:lat-lng":function($event){_vm.addressLatLng=$event}}}):_vm._e()],1)],1),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"accent"},on:{"click":function($event){return _vm.addAddress()}}},[_vm._v("Add")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.addresses,"loading":_vm.loading},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.map",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.onShowMap(item)}}},[_c('v-icon',[_vm._v("map")])],1)]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.note))]}},{key:"item.isPrimary",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":item.isPrimary,"color":"primary","hide-details":""},on:{"change":function($event){return _vm.onUpdate($event, 'isPrimary', item)},"~click":function($event){$event.stopPropagation();}}})]}},{key:"item.isFavourite",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":item.isFavourite,"color":"primary","hide-details":""},on:{"change":function($event){return _vm.onUpdate($event, 'isFavourite', item)},"~click":function($event){$event.stopPropagation();}}})]}},{key:"item.isBilling",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":item.isBilling,"color":"primary","hide-details":""},on:{"change":function($event){return _vm.onUpdate($event, 'isBilling', item)},"~click":function($event){$event.stopPropagation();}}})]}},{key:"item.isCollection",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":item.isCollection,"color":"primary","hide-details":""},on:{"change":function($event){return _vm.onUpdate($event, 'isCollection', item)},"~click":function($event){$event.stopPropagation();}}})]}},{key:"item.isDelivery",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":item.isDelivery,"color":"primary","hide-details":""},on:{"change":function($event){return _vm.onUpdate($event, 'isDelivery', item)},"~click":function($event){$event.stopPropagation();}}})]}},{key:"item.removeAddress",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteAddress(item.id)}}},[_vm._v("Delete")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.aggregateAddress(item)))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }