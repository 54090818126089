<template>
  <div>
    <v-dialog
      v-model="mapDialog"
      scrollable
      fullscreen
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="mapDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Address Location</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <leaflet-map
          v-if="mapDialog"
          v-bind:lat-lng.sync="addressLatLng"
          :deliveryRadius="deliveryRadius"
          :collectionRadius="collectionRadius"
          :isPrimaryAddress="isPrimaryAddress"
          readonly
        ></leaflet-map>
      </v-card>
    </v-dialog>
    <v-btn color="accent" @click="addAddress()" class="mb-2">Add</v-btn>
    <v-data-table
      :headers="headers"
      :items="addresses"
      class="elevation-1"
      :loading="loading"
      @click:row="onRowClick"
    >
      <template v-slot:item.map="{ item }">
        <v-btn color="primary" text @click.stop="onShowMap(item)">
          <v-icon>map</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.note="{ item }"> {{ item.note }}</template>
      <template v-slot:item.isPrimary="{ item }">
        <v-checkbox
          :input-value="item.isPrimary"
          color="primary"
          hide-details
          @change="onUpdate($event, 'isPrimary', item)"
          @click.once.stop
          class="mt-0"
        ></v-checkbox>
      </template>
      <template v-slot:item.isFavourite="{ item }">
        <v-checkbox
          :input-value="item.isFavourite"
          color="primary"
          hide-details
          @change="onUpdate($event, 'isFavourite', item)"
          @click.once.stop
          class="mt-0"
        ></v-checkbox>
      </template>
      <template v-slot:item.isBilling="{ item }">
        <v-checkbox
          :input-value="item.isBilling"
          color="primary"
          hide-details
          @change="onUpdate($event, 'isBilling', item)"
          @click.once.stop
          class="mt-0"
        ></v-checkbox>
      </template>
      <template v-slot:item.isCollection="{ item }">
        <v-checkbox
          :input-value="item.isCollection"
          color="primary"
          hide-details
          @change="onUpdate($event, 'isCollection', item)"
          @click.once.stop
          class="mt-0"
        ></v-checkbox>
      </template>
      <template v-slot:item.isDelivery="{ item }">
        <v-checkbox
          :input-value="item.isDelivery"
          color="primary"
          hide-details
          @change="onUpdate($event, 'isDelivery', item)"
          @click.once.stop
          class="mt-0"
        ></v-checkbox>
      </template>
      <template v-slot:item.removeAddress="{ item }">
        <v-btn color="error" @click.stop="deleteAddress(item.id)">Delete</v-btn>
      </template>
      <template v-slot:item.address="{ item }">{{
        aggregateAddress(item)
      }}</template>
    </v-data-table>
  </div>
</template>

<script>
  import Vue from "vue";
  import LeafletMap from "@/components/LeafletMap.vue";
  import { store } from "../../store";
  import("@/store/modules/addresses").then(({ addresses }) => {
    if (!store.state.addresses) store.registerModule("addresses", addresses);
    else return;
  });

  export default Vue.extend({
    name: "Addresses",

    components: { LeafletMap },

    data: function () {
      return {
        addressLatLng: {},
        mapDialog: false,

        deliveryRadius: 0,
        collectionRadius: 0,
        isPrimaryAddress: false,

        headers: [
          {
            text: "Show on map",
            align: "left",
            value: "map",
          },
          {
            text: "Address Name",
            align: "left",
            value: "name",
          },
          {
            text: "Delivery Notes",
            align: "left",
            value: "note",
          },
          {
            text: "Address",
            value: "address",
          },
          {
            text: "Primary",
            value: "isPrimary",
          },
          {
            text: "Favourite",
            value: "isFavourite",
          },
          {
            text: "Billing",
            value: "isBilling",
          },
          {
            text: "Collection",
            value: "isCollection",
          },
          {
            text: "Delivery",
            value: "isDelivery",
          },
          {
            text: "Remove Address",
            value: "removeAddress",
          },
        ],
      };
    },

    computed: {
      addresses: function () {
        return this.$store.state.addresses.addresses;
      },
      loading: function () {
        return this.$store.state.addresses.loading;
      },
      deliveryRestrictions: function () {
        return this.$store.getters["orders/deliveryRestrictions"];
      },
    },

    watch: {
      deliveryRestrictions: {
        deep: true,
        immediate: true,
        handler() {
          if (this.deliveryRestrictions.distance != null) {
            this.deliveryRadius = this.deliveryRestrictions.distance * 1000;
          }
        },
      },
    },

    methods: {
      onShowMap(address) {
        this.mapDialog = true;
        this.addressLatLng = { lat: address.latitude, lng: address.longitude };
        this.isPrimaryAddress = address.isPrimary;
      },

      onRowClick(item) {
        this.$store.dispatch("addresses/setSelectedAddress", item);
        this.$router.push({
          name: `address-edit`,
          params: { addressId: "" + item.id },
        });
      },

      aggregateAddress(address) {
        const addressValues = [
          `${address.streetNumber} ${address.streetName}`,
          address.suburb,
          address.city,
          address.province,
        ];
        const aggregatedValues = addressValues.filter(Boolean).join(", ");
        return aggregatedValues;
      },

      onUpdate(val, index, address) {
        address[index] = val;
        this.$store.dispatch("addresses/updateAddress", address);
      },

      addAddress() {
        this.$store.dispatch("addresses/setSelectedAddress");
        this.$router.push({ name: `address-create` });
      },

      deleteAddress(id) {
        this.$store.dispatch("addresses/deleteAddress", id);
      },
    },

    mounted: function () {
      this.$store.dispatch("addresses/fetchAddresses");
      this.$store.dispatch("orders/fetchDeliveryRestrictions");
    },
  });
</script>

<style>
  .flex.full-label {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
</style>
