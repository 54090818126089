<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <S2SCard title="Organisation Profile" elevation="0">
          <v-layout row wrap>
            <v-flex xs12 md6>
              <v-text-field
                label="Organisation Name"
                v-model="shopName"
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6>
              <v-text-field
                label="Organisation Code"
                :value="activeShop.code"
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6>
              <v-text-field
                label="Contact Number"
                :value="activeShop.profile.contactNumber || 'N/A'"
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6>
              <v-text-field
                label="Organisation ID"
                :value="activeShop.id"
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="text-xs-left">
              <span>Type:</span>
              <v-chip
                label
                color="primary"
                class="ml-2"
                v-for="type of activeShop.types"
                :key="type.id"
                outlined
                >{{ type.name }}</v-chip
              >
            </v-flex>
          </v-layout>
        </S2SCard>
      </v-col>
      <v-col cols="12" sm="6">
        <S2SCard title="Business Profile" elevation="0">
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Business Name"
                  v-model="activeShop.billingProfile.businessName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="VAT number"
                  v-model="activeShop.billingProfile.vatNumber"
                  :rules="[(v) => !!v || 'VAT number is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Contact Name"
                  v-model="activeShop.billingProfile.contactName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Contact Number"
                  v-model="activeShop.billingProfile.phoneNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Email"
                  v-model="activeShop.billingProfile.email"
                  :rules="[(v) => verifyEmail(v) || 'Email format incorrect']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Address"
                  v-model="activeShop.billingProfile.address"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              @click="updateBillingInfo()"
              color="accent"
              :disabled="emptyFields()"
              >Update</v-btn
            >
          </v-form>
        </S2SCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <S2SCard title="My Logo" elevation="0">
          <SimpleDialog v-model="confirm" title="Image Confirmation">
            <v-img ref="previewImage" :src="previewImg"></v-img>
            <v-btn text slot="button-container" @click="confirm = false"
              >Cancel</v-btn
            >
            <v-btn color="primary" slot="button-container" @click="uploadImg"
              >Upload</v-btn
            >
          </SimpleDialog>
          <v-flex md3 sm6 xs12 v-if="activeShop.profile.image">
            <v-card text tile class="d-flex">
              <v-img
                :src="`${imgBaseUrl + activeShop.profile.image}`"
                class="elevation-2"
              >
                <v-layout
                  fill-height
                  align-center
                  justify-center
                  ma-0
                  v-if="loading"
                >
                  <v-progress-circular
                    :indeterminate="loading"
                    color="accent"
                    size="40"
                  ></v-progress-circular>
                </v-layout>
              </v-img>
            </v-card>
          </v-flex>

          <v-flex xs12 class="text-sm-left">
            <v-btn color="primary" class="mt-2" @click="onImageUpload">
              {{
                activeShop.profile.image
                  ? "Upload New Logo"
                  : "Please Upload Shop Logo"
              }}
            </v-btn>
          </v-flex>
          <input
            ref="image"
            type="file"
            v-on:change="imageSelected"
            accept="image/png, image/jpeg"
            style="display: none"
          />
        </S2SCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Vue from "vue";
  import SimpleDialog from "@/components/SimpleDialog.vue";

  export default Vue.extend({
    name: "MyShopProfile",

    components: { SimpleDialog },

    data: function () {
      return {
        imgBaseUrl: process.env.VUE_APP_IMG_BASE,
        confirm: false,
        selectedImg: null,
        previewImg: "",
        thisShopName: "",
        isDirty: false,
        formValid: false,
      };
    },

    computed: {
      activeShop: function () {
        return this.$store.state.shops.activeShop;
      },
      loading: function () {
        return this.$store.state.shops.loading;
      },
      shopName: {
        get() {
          return this.activeShop.name;
        },
        set(v) {
          this.thisShopName = v;
        },
      },
    },

    methods: {
      onImageUpload(e) {
        this.$refs["image"].click();
      },
      imageSelected(e) {
        this.confirm = true;
        this.setPreviewImg(e);
        this.selectedImg = e.target.files[0];
      },
      uploadImg() {
        if (this.selectedImg) {
          const formData = new FormData();
          formData.append("image", this.selectedImg);
          this.$store.dispatch("shops/uploadImage", formData);
        }

        this.confirm = false;
      },
      setPreviewImg(e) {
        const reader = new FileReader();
        reader.onload = () => {
          const output = this.$refs["previewImage"];
          this.previewImg = reader.result;
        };

        reader.readAsDataURL(e.target.files[0]);
      },
      verifyEmail(email) {
        if (email == "") {
          return true;
        } else {
          return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
          );
        }
      },
      updateBillingInfo() {
        if (this.$refs.form.validate()) {
          this.$store.dispatch("shops/updateOrgBusinessDetails");
        }
      },
      emptyFields() {
        let empty = true;
        Object.keys(this.activeShop.billingProfile).forEach((key) => {
          if (this.activeShop.billingProfile[key] != "") {
            empty = false;
            return;
          }
        });
        return empty;
      },
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
