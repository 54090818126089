var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"accent"},on:{"click":function($event){return _vm.onAdd()}}},[_vm._v("Add")]),[_c('div',{staticClass:"title ma-3"},[_vm._v("Emails")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.notificationSubscription,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onEditNotifier(item)}}},[_c('v-icon',[_vm._v("edit")])],1)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.onRemoveNotifier(item.email)}}},[_vm._v("delete")])]}}])}),_c('v-divider',{staticClass:"mt-5 mb-5"}),_c('v-card',{staticClass:"pb-5",attrs:{"tile":""}},[_c('v-card-title',[_vm._v("Push Notification Settings")]),_c('v-card-text',[_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.stepsPushy),callback:function ($$v) {_vm.stepsPushy=$$v},expression:"stepsPushy"}},_vm._l((_vm.availablePushySubscriptions),function(availableSubscription,index,num){return _c('div',{key:index},[_c('v-stepper-step',{attrs:{"editable":"","step":++num,"complete":_vm.stepsPushy > num}},[_vm._v(" "+_vm._s(index.charAt(0).toUpperCase() + index.slice(1))+" ")]),_c('v-stepper-content',{staticClass:"ma-0 pa-0",attrs:{"step":num}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',[_c('v-stepper',{attrs:{"value":"1","non-linear":""}},[_c('v-stepper-header',_vm._l((availableSubscription),function(available,index,num){return _c('v-stepper-step',{key:index,attrs:{"editable":"","complete":"","step":++num}},[_vm._v(" "+_vm._s(index)+" ")])}),1),_vm._l((availableSubscription),function(available,index,num){return _c('v-stepper-content',{key:index,staticClass:"ma-0",attrs:{"step":++num}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header,"hide-default-header":"","items":available},scopedSlots:_vm._u([{key:"item.subscribed",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"input-value":_vm.userHasSubscribed(item, true)},on:{"change":function($event){return _vm.updateUserSubscription($event, item, true)}}})]}}],null,true)})],1)})],2)],1)],1)],1)],1)}),0)],1),_c('v-card-actions',{staticClass:"pb-5",attrs:{"justify-right":""}},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"accent"},on:{"click":function($event){return _vm.savePushy()}}},[_vm._v("Save Push Settings")])],1)],1),_c('v-dialog',{attrs:{"title":"Add New Notifier","max-width":"800px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":"","id":"notifierCard"}},[_c('v-card-title',[_vm._v("Notifier Information")]),_c('v-card-text',[(_vm.isNew)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"name":"firstName","label":"Name","rules":[function (v) { return !!v || 'Name is required'; }]},model:{value:(_vm.notifierObject.name),callback:function ($$v) {_vm.$set(_vm.notifierObject, "name", $$v)},expression:"notifierObject.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"name":"surname","label":"Surname","rules":[function (v) { return !!v || 'Surname is required'; }]},model:{value:(_vm.notifierObject.surname),callback:function ($$v) {_vm.$set(_vm.notifierObject, "surname", $$v)},expression:"notifierObject.surname"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"name":"email","label":"Email","rules":[
                    function (v) { return !!v || 'Email is required'; },
                    function (v) { return _vm.verifyEmail(v) || 'Email format incorrect'; } ]},model:{value:(_vm.notifierObject.email),callback:function ($$v) {_vm.$set(_vm.notifierObject, "email", $$v)},expression:"notifierObject.email"}})],1)],1)],1):_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"name":"fullName","label":"Fullname","rules":[function (v) { return !!v || 'Name & Surname is required'; }]},model:{value:(_vm.notifierObject.person),callback:function ($$v) {_vm.$set(_vm.notifierObject, "person", $$v)},expression:"notifierObject.person"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"name":"email","label":"Email","rules":[
                    function (v) { return !!v || 'Email is required'; },
                    function (v) { return _vm.verifyEmail(v) || 'Email format incorrect'; } ]},model:{value:(_vm.notifierObject.email),callback:function ($$v) {_vm.$set(_vm.notifierObject, "email", $$v)},expression:"notifierObject.email"}})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v("Notification Settings")]),_c('v-card-text',[_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.steps),callback:function ($$v) {_vm.steps=$$v},expression:"steps"}},_vm._l((_vm.availableNotificationSubscriptions),function(availableSubscription,index,num){return _c('div',{key:index},[_c('v-stepper-step',{attrs:{"editable":"","step":++num,"complete":_vm.steps > num}},[_vm._v(" "+_vm._s(index.charAt(0).toUpperCase() + index.slice(1)))]),_c('v-stepper-content',{staticClass:"ma-0 pa-0",attrs:{"step":num}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',[_c('v-stepper',{attrs:{"value":"1","non-linear":""}},[_c('v-stepper-header',_vm._l((availableSubscription),function(available,index,num){return _c('v-stepper-step',{key:index,attrs:{"editable":"","complete":"","step":++num}},[_vm._v(" "+_vm._s(index)+" ")])}),1),_vm._l((availableSubscription),function(available,index,num){return _c('v-stepper-content',{key:index,staticClass:"ma-0",attrs:{"step":++num}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header,"hide-default-header":"","items":available},scopedSlots:_vm._u([{key:"item.subscribed",fn:function(ref){
                  var item = ref.item;
return [_c('v-checkbox',{attrs:{"input-value":_vm.userHasSubscribed(item)},on:{"change":function($event){return _vm.updateUserSubscription($event, item)}}})]}}],null,true)})],1)})],2)],1)],1)],1)],1)}),0)],1),_c('v-card-actions',{staticClass:"pb-5",attrs:{"justify-right":""}},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"accent"},on:{"click":function($event){return _vm.saveNotifier()}}},[_vm._v("Save Settings")]),_c('v-btn',{staticClass:"ml-2",attrs:{"text":""},on:{"click":function($event){return _vm.cancelNotifier()}}},[_vm._v("Cancel")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}},[_c('v-card',[_c('v-card-title',[_vm._v("Are you sure?")]),_c('v-card-text',[_vm._v(" You are about to remove "+_vm._s(_vm.confirmRemoveNotifier)+". ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onCancelRemoveNotifier()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.onConfirmRemoveNotifier()}}},[_vm._v("Yes")])],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }